import Routerpage from './routes/Routes';

const App =()=>{
 
  return(
    <>
  <div className='App'>
     < Routerpage />
     </div>
    </>
  )
}

export default App;
