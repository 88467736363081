import React, { useEffect, useState } from 'react';
//import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";
import 'react-multi-carousel/lib/styles.css';
function Home() {
  //  Check if the URL contains an encoded token
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("var"); 
if (token) {
  // Decode the token and extract the payload data
  try{
    const decoded = jwt_decode(token);
  const { email, role, id, user_token } = decoded;

  if(email == undefined || role == undefined || id == undefined || user_token == undefined){
    window.location.href = "https://www.jacanawarranty.com/";
  }


  // Step 3: Save the payload data in the session
  sessionStorage.setItem("email", email);
  sessionStorage.setItem("role", role);
  sessionStorage.setItem("id", id);
  sessionStorage.setItem("user_token", user_token);
  // Step 4: Check the role and redirect accordingly
  if (role.includes('customer')) {
    // Redirect to https://www.jacanawarranty.com/
    window.location.href = "/user_dashboard"
  }else{
    window.location.href = "https://www.jacanawarranty.com/";
  }
  }catch (error) {
    window.location.href = "https://www.jacanawarranty.com/";
  }
  
} else {
  if(sessionStorage.getItem("email") !=null && sessionStorage.getItem("id") !=null && sessionStorage.getItem("user_token") !=null && sessionStorage.getItem("role") != null){
    window.location.href = "/user_dashboard";
  }else{
    // Step 5: Handle the case when the URL does not have an encoded token
  window.location.href = "https://www.jacanawarranty.com/";
  }
}


  return (
    <>
     <div id="loftloaderwrappernew"></div>
    </>
  );
}

export default Home; 