import React, { useEffect , useState} from 'react';
import 'react-multi-carousel/lib/styles.css';
import product from "../../assets/img/home/product.png";
import warranty from "../../assets/img/home/quality.png";
import subscription from "../../assets/img/home/subscription.png";
import claim from "../../assets/img/home/claim.png";
import status from "../../assets/img/home/status.png";
import { Form } from 'react-bootstrap';


    function UserDashboard() {
      const [apiLoading, setApiLoading] = useState(false);
      const [apiData, setApiData] = useState(null);
      const [loading, setLoading] = useState(true);
      const [selectedOption, setSelectedOption] = useState('');
      const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage, setItemsPerPage] = useState(10);
      const [selectedOptionCount, setSelectedOptionCount] = useState(0);


      const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setCurrentPage(1); // Reset the current page to 1 when changing the selected option
      };
    
      const handlePaginationChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
      };
    

      
      useEffect(() => {
        const email = sessionStorage.getItem('email');
        const user_token = sessionStorage.getItem('user_token');
        const id = sessionStorage.getItem('id');
        const role = sessionStorage.getItem('role');
        
    
        if (email && role && id && user_token) {
          const fetchData = async () => {
            setApiLoading(true);
            console.log("payment-status" , selectedOption);
            
            try {
              const apiUrl = 'https://jacanawarranty.com/wp-json/react/v1/home';
              const params = {
                email: email,
                user_token: user_token,
                page: currentPage ,
                page_size: itemsPerPage,
                status: selectedOption, // Add the selected payment status to the params object
              };
              const queryString = new URLSearchParams(params).toString();
              const url = `${apiUrl}?${queryString}`;
              const headers = {
                Authorization: 'Bearer dszlpys0ro345ci2ux8jxewp736zdxty',
              };
    
              const response = await fetch(url, { headers });
              const data = await response.json();
              console.log("data-status" , data);
    
              setApiData(data);
              setLoading(false);

             // Calculate the count of selected payment_status
             let count = 0;

          if (data && data.data && data.data.total_count) {
            count = data.data.total_count;
          }

 
             
             // Update the selectedOptionCount state variable
             setSelectedOptionCount(count);

             console.log("Selected Option Count:", count);

             console.log("Selected Option:", selectedOption);

              
            } catch (error) {
              console.error('API request failed:', error);
              setLoading(false);
            } finally {
              setApiLoading(false);
            }
          };
    
          fetchData();
        } else {
          // Handle the case when the URL does not have an encoded token
          window.location.href = 'https://www.jacanawarranty.com/';
        }
      }, [currentPage, itemsPerPage, selectedOption ]);
    
    
      let filteredData = [];
      if (apiData && apiData.data && Array.isArray(apiData.data.data)) {
        filteredData = apiData.data.data;
      }
    
      const totalPages = Math.ceil(apiData && apiData.data ? apiData.data.total_count / itemsPerPage : 0);
    
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const pageNumbers = [];
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }


      return(
          <>

<div className="dashboard-top-section">
  <div className="container">
    <div className="row">
    
     <h1>UserDashboard</h1>
     
      </div>
      </div>
  </div>

  
  <div className="dashboard-second-section">
  <div className="container">
    <div className="row justify-content-around">
    
    <div className="col-md-2 each-box-image">
    <h3>PRODUCTS</h3>
    <img src={product} alt="product"/>
    </div>
    <div className="col-md-2 each-box-image">
    <h3>WARRANTY</h3>
    <img src={warranty} alt="warranty"/>
    </div>
    <div className="col-md-2 each-box-image">
    <h3>SUBSCRIPTION</h3>
    <img src={subscription} alt="subscription"/>
    </div>
    <div className="col-md-2 each-box-image">
    <h3>FILE CLAIM</h3>
    <img src={claim} alt="claim"/>
    </div>
    <div className="col-md-2 each-box-image">
    <h3>STATUS</h3>
    <img src={status} alt="status"/>
    </div>
     
      </div>
      </div>
  </div>


  <div className="dashboard-third-section">
  <div className="container">

  <div className="row justify-content-around">
 <div>
    {loading ? (
      // Show the loader while loading is true
      <div id="table_loader">
      </div>
    ) : (
      // Once loading is false, display the API data
      <div>
        <div className='col-md-4 justify-content-end mb-4'>
        <Form>
        <label>Payment Status</label>
        <Form.Select value={selectedOption} onChange={handleSelectChange}>
          <option value="">All</option>
          <option value="Active">Active</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Suspended">Suspended</option>
          <option value="Refunded">Refunded</option>
        </Form.Select>
      </Form>
</div>
        {/* <p>Total Count: {apiData && apiData.data ? apiData.data.total_count : 0}</p> */}
        {/* <p>Total pages: {totalPages}</p> */}
        {/* Pagination */}
  
<div>
      

      {apiLoading && (
        <div id="table_loader">
          {/* Loader or spinner component */}
        </div>
      )}

      {!apiLoading && (
        <div>
          {/* Display the data */}


          <table>
          {/* Render table headers */}
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Order ID</th>
              <th>Term</th>
              <th>Renewal Date</th>
              <th>ID</th>
              <th>Payment Status</th>
              <th>Action</th>
              <th>Cancel</th>
              <th>Transaction History</th>
            </tr>
          </thead>

          {/* Render table body */}
          <tbody>
          {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.product_name}</td>
                <td>{item.order_id}</td>
                <td>{item.term}</td>
                <td>{item.renewal_date}</td>
                <td>{item.id}</td>
                <td>{item.payment_status}</td> 
                <td>{item.button}</td>
                <td>
          {item.payment_status === 'Active' && (
            <a class="btn btn-danger" href="/">Cancel</a>
          )}
        </td>
                <td><a href="/">History</a></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}

<div className="pagination-container">
        
     
        <label className="pagination-label">Total Count: {selectedOptionCount}</label>
        <div className="pagination-controls">
        <label className="pagination-label">Current Page: {currentPage}</label>
          {/* Pagination select */}
          <select value={itemsPerPage} onChange={handlePaginationChange}>
            {[10, 20, 30, 40, 50].map((option) => (
              <option key={option} value={option}>
                {option} 
              </option>
            ))}
          </select>

          {/* Previous button */}
          <button  className="pagination-button" onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </button>

          {/* Next button */}
          <button  className="pagination-button" onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>

    </div>


      </div>
    )}
  </div>




  </div>
  </div>
  </div>


      </>
      )
  }
  

export default UserDashboard;

